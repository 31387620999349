<template>
  <b-collapse
    v-if="userHasRoleSuperAdmin"
    animation="slide"
    :open.sync="open"
    class="table-filter"
  >
    <template #trigger>
      <b-button
        :label="$t('filter')"
        icon-left="filter"
        :class="{ 'button-icon-primary': active }"
      ></b-button>
      <b-button
        v-show="active"
        :label="$t('clear')"
        icon-left="close"
        type="is-text"
        @click.stop="clearFilter"
      ></b-button>
    </template>

    <div class="columns mt-0">
      <div v-if="'search' in filter" class="column is-one-quarter">
        <CustomInput
          v-model="debouncedSearch"
          :type="'search'"
          :label="labels.search || $tc('search')"
          :placeholder="$t('search')"
          :horizontal="false"
          :required="false"
          :icon="'magnify'"
        ></CustomInput>
      </div>

      <div v-if="'country' in filter" class="column is-one-quarter">
        <b-field :label="labels.country || $tc('country')" label-for="country">
          <b-select
            id="country"
            v-model="filter.country"
            :placeholder="labels.country || $tc('country')"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option v-for="c in countries" :key="c.code" :value="c.code">
              {{ c.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'region' in filter" class="column is-one-quarter">
        <b-field :label="labels.region || $tc('region')" label-for="region">
          <b-select
            id="region"
            v-model="filter.region"
            :placeholder="labels.region || $tc('region')"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option v-for="r in regions" :key="r.code" :value="r.code">
              {{ r.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'product' in filter" class="column is-one-quarter">
        <b-field :label="labels.product || $tc('product')" label-for="product">
          <b-select
            id="product"
            v-model="filter.product"
            :placeholder="labels.product || $tc('product')"
            :loading="!products"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option v-for="p in products" :key="p.id" :value="p['@id']">
              {{ p.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'productType' in filter" class="column is-one-quarter">
        <b-field
          :label="labels.productType || $tc('product_type')"
          label-for="productType"
        >
          <b-select
            id="productType"
            v-model="filter.productType"
            :placeholder="labels.productType || $tc('product_type')"
            :loading="!productTypes"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option v-for="a in productTypes" :key="a.id" :value="a['@id']">
              {{ a.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'partnerRole' in filter" class="column is-one-quarter">
        <b-field
          :label="labels.partnerRole || $tc('partner_role')"
          label-for="partnerRole"
        >
          <b-select
            id="partnerRole"
            v-model="filter.partnerRole"
            :placeholder="labels.partnerRole || $tc('partner_role')"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option
              v-for="p in partnerRolesInCustomOrder"
              :key="p.id"
              :value="p['@id']"
            >
              {{ p.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'company' in filter" class="column is-one-quarter">
        <b-field :label="labels.company || $tc('company')" label-for="company">
          <b-select
            id="company"
            v-model="filter.company"
            :placeholder="labels.company || $tc('company')"
            :loading="!companies"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option v-for="c in companies" :key="c.id" :value="c['@id']">
              {{ c.name }}
              {{ c.blocked ? `(${$t("deactivated")})` : "" | lc }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'contract' in filter" class="column is-one-quarter">
        <b-field
          :label="labels.contract || $tc('contract')"
          label-for="contract"
        >
          <b-select
            id="contract"
            v-model="filter.contract"
            :placeholder="labels.contract || $tc('contract')"
            :loading="!contracts"
          >
            <option :value="null">{{ $t("all") }}</option>
            <option v-for="c in contracts" :key="c.id" :value="c['@id']">
              {{ c.company.name }}
              ({{ c.startDate | date }} &ndash; {{ c.endDate | date }})
            </option>
          </b-select>
        </b-field>
      </div>

      <div v-if="'date' in filter" class="column is-one-quarter">
        <CustomDatePicker
          v-model="filter.date"
          :label="labels.date || $t('date')"
          :placeholder="labels.date || $t('date')"
          :horizontal="false"
          :range="true"
          :required="false"
        ></CustomDatePicker>
      </div>

      <div v-if="'checkbox' in filter" class="column is-one-quarter">
        <b-field class="checkbox">
          <b-checkbox v-model="filter.checkbox">
            {{ labels.checkbox }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { debounce, sortBy } from "lodash-es";
import { mapActions, mapGetters } from "vuex";
import Countries from "../helpers/Countries";
import Regions from "../helpers/Regions";
import CustomDatePicker from "./forms/CustomDatePicker";
import CustomInput from "./forms/CustomInput";

export default {
  components: { CustomDatePicker, CustomInput },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      open: window.innerWidth > 768,
      countries: Countries.all,
      regions: Regions.all,
      companies: null,
      contracts: null,
      productTypes: null,
      products: null,
    };
  },
  computed: {
    ...mapGetters(["partnerRolesInCustomOrder", "userHasRoleSuperAdmin"]),
    active() {
      return Object.keys(this.filter).some((k) => {
        if (Array.isArray(this.filter[k])) {
          return this.filter[k].some((v) => v !== null);
        }
        return !!this.filter[k];
      });
    },
    debouncedSearch: {
      get() {
        return this.filter.search;
      },
      set: debounce(function (value) {
        this.filter.search = value;
      }, 200),
    },
  },
  watch: {
    open(value) {
      if (!value) {
        return;
      }
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions([
      "getAllCompanies",
      "getAllContracts",
      "getAllProductTypes",
      "getAllProducts",
    ]),
    load() {
      // This component is only visible for super admins,
      // so don't load data if user isn't a super admin
      if (!this.userHasRoleSuperAdmin) {
        return;
      }

      if ("company" in this.filter || "contract" in this.filter) {
        this.getAllCompanies({ blocked: false }).then((companies) => {
          this.companies = companies;
        });
      }

      if ("contract" in this.filter) {
        this.getAllContracts().then(async (contracts) => {
          this.contracts = sortBy(contracts, "company.name");
        });
      }

      if ("productType" in this.filter) {
        this.getAllProductTypes().then((productTypes) => {
          this.productTypes = productTypes;
        });
      }

      if ("product" in this.filter) {
        this.getAllProducts().then((products) => {
          this.products = products;
        });
      }
    },
    clearFilter() {
      Object.keys(this.filter).forEach((k) => {
        this.filter[k] = null;
      });
    },
  },
};
</script>
