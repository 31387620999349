<template>
  <div>
    <div class="page-header">
      <h1>{{ $tc("contract", 2) }}</h1>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="plus"
        tag="router-link"
        :to="{ name: 'ContractCreate' }"
      >
        {{ $t("contract_create") }}
      </b-button>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <TableFilter
          :filter="filter"
          :labels="{
            date: $t('next_evaluation_date'),
            checkbox: $t('include_expired'),
          }"
        ></TableFilter>

        <b-table
          ref="table"
          :current-page="page"
          :data="contracts"
          :loading="loading"
          :total="total"
          backend-pagination
          backend-sorting
          :default-sort="order.by"
          :default-sort-direction="order.direction"
          class="is-clickable mt-2"
          hoverable
          paginated
          pagination-simple
          pagination-size="is-small"
          per-page="30"
          :row-class="(row) => row.expired && 'has-text-grey-light'"
          @click="
            (e) =>
              this.$router.push({ name: 'ContractView', params: { id: e.id } })
          "
          @page-change="(e) => (page = e)"
          @sort="sort"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column
            field="contractNumber"
            :label="$t('contract_number')"
            sortable
          >
            <template v-slot="props">
              {{ props.row.contractNumber }}
            </template>
          </b-table-column>

          <b-table-column
            :visible="userHasRoleSuperAdmin"
            :label="$tc('company')"
          >
            <template v-slot="props">
              {{ props.row.company.name }}
            </template>
          </b-table-column>

          <b-table-column field="startDate" :label="$tc('start_date')" sortable>
            <template v-slot="props">
              {{ props.row.startDate | date }}
            </template>
          </b-table-column>

          <b-table-column field="endDate" :label="$tc('end_date')" sortable>
            <template v-slot="props">
              {{ props.row.endDate | date }}
            </template>
          </b-table-column>

          <b-table-column
            field="nextContractEvaluationDate"
            :label="$tc('next_evaluation_date')"
            sortable
          >
            <template v-slot="props">
              {{ props.row.nextContractEvaluationDate | date }}
            </template>
          </b-table-column>

          <b-table-column
            :visible="userHasRoleSuperAdmin"
            :label="$tc('estimated_royalty')"
            numeric
          >
            <template v-slot="props">
              <span v-if="props.row.currency">
                {{ props.row.estimate | money(props.row.currency.code) }}
              </span>
              <span v-else>–</span>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import TableFilter from "../../components/TableFilter";

export default {
  name: "ContractIndex",
  components: { TableFilter },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      filter: {
        search: null,
        company: null,
        date: [null, null],
        checkbox: false,
      },
      order: {
        by: "contractNumber",
        direction: "desc",
      },
      contracts: [],
    };
  },
  computed: {
    ...mapGetters(["findCurrencyByUri", "userHasRoleSuperAdmin"]),
  },
  watch: {
    page() {
      this.load();
    },
    filter: {
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    // Show expired contracts by default for non super admins
    this.filter.checkbox = !this.userHasRoleSuperAdmin;

    this.load();
  },
  methods: {
    ...mapActions(["getAllCompanies", "getContracts"]),
    load() {
      this.loading = true;

      this.getContracts({
        page: this.page,
        contractNumber: this.filter.search,
        company: this.filter.company,
        endDateAfter: this.filter.checkbox
          ? null
          : dayjs().format("YYYY-MM-DD"),
        nextContractEvaluationDateAfter: this.filter.date?.[0],
        nextContractEvaluationDateBefore: this.filter.date?.[1],
        orderBy: this.order.by,
        orderDirection: this.order.direction,
      }).then(async ({ items, total }) => {
        for (const i of items) {
          i.currency = this.findCurrencyByUri(i.currency["@id"]);
          i.expired = dayjs(i.endDate).isBefore(dayjs(), "day");
          i.estimate = i.pricePerDistanceUnit * i.distanceUnits;
        }
        this.contracts = items;
        this.total = total;
        this.loading = false;
      });
    },
    sort(orderBy, orderDirection) {
      this.order.by = orderBy;
      this.order.direction = orderDirection;
      this.load();
    },
  },
};
</script>
